.selection {
  background: #fff;
  width: 90%;
  display: grid;
  position: relative;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 5px;
}
.selection p {
  cursor: pointer;
}

.jsonToExcelBtn {
  padding: 10px;
  font-size: 16px;
  background-color: #15c39a;
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
}
