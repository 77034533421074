@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --grey-color: #b1adad;
  --border-color: #e7e8ea;
}
.dash-logout{
  position: absolute;
  top: 5%;
  right: 2%;
}
.dash-container {
  display: flex;
  height: 100%;
  letter-spacing: 1px;
  width: 100%;
  background-color: #fff;
  position: absolute;
  z-index: 999;
}

.menu_item.active {
  background-color: #d8d8d86b; /* Change to desired active background color */
  color: #daa520;
  border-radius: 10px;
}

.menu_item:hover {
  background-color: #d8d8d86b; /* Change to desired hover background color */
  color: #daa520; 
  border-radius: 10px;
}

.menu_item.active:hover {
  background-color: #d8d8d86b; /* Change to desired active hover background color */
  color: #daa520;
}

.dark-mode {
  background: #000;
  color: #fff;
}

/*----- Left SideBar -----*/

.left_sidebar {
  flex-basis: 20%;
  position: sticky;
  top: 0px;
  align-self: flex-start;
  transition: all 0.3s ease-in-out;
}
.close_btn {
  position: absolute;
  top: 5%;
  left: 10%;
  z-index: 120;
  color: #000;
  border: none;
  font-size: 30px;
  background-color: transparent;
  display: none;
}

@media   (max-width: 900px) {
  .close_btn{
    display: block;
  }
}
.left_sidebar .close_hamburger_btn {
  position: absolute;
  top: 30px;
  left: 30px;
  font-size: 34px;
  cursor: pointer;
  color: #000;
  display: none;
}

.left_sidebar .logo h2 {
  padding: 20px 30px;
  font-weight: 600;
  font-size: 32px;
  font-style: italic;
}

.left_sidebar .menu_items .menu_item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 17px;
  /* color: var(--grey-color); */
  padding: 20px 30px;
  cursor: pointer;
}

/*----- Main Content -----*/

.main_content {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 20px;
  transition: all 0.3s ease-in-out;
  overflow-y: scroll;
}

.main_content .main_navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.main_content .main_navbar .dark_mode_icon i {
  background: var(--border-color);
  color: #000;
  font-size: 18px;
  padding: 10px;
  margin-top: 15px;
  border-radius: 50%;
  cursor: pointer;
}

.main_content .main_navbar .dark_mode_icon .bx-sun {
  display: none;
}

.main_content .left_right_sidebar_opener {
  font-size: 45px;
  cursor: pointer;
  margin-top: 20px;
  display: none;
}

.main_content .left_right_sidebar_opener .student {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main_content .left_right_sidebar_opener .student img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
  margin-top: 15px;
}

.main_content .left_right_sidebar_opener .student p {
  font-size: 18px;
}

.search_box {
  background: var(--border-color);
  display: flex;
  gap: 15px;
  padding: 12px;
  margin-top: 20px;
  align-items: center;
  color: #000;
  width: 100%;
  border-radius: 5px;
  font-size: 20px;
  width: 90%;
}

.search_box input {
  width: 100%;
  border: none;
  background: #e9eaec;
  outline: none;
}

.menu_item_name_and_filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 4px 0;
  width: 50%;
}

.menu_item_name_and_filter .main_navbar {
  width: 100%;
}

.menu_item_name_and_filter .menu_item_name h2 {
  font-weight: 600;
  font-size: 24px;
}

.menu_item_name_and_filter .filter_and_sort {
  display: flex;
  gap: 20px;
  align-items: center;
}

.menu_item_name_and_filter .filter_and_sort .sort_and_filter {
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--border-color);
  color: #000;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
}

.tabs {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  margin-left: 15px;
}

.tabs .tab_name {
  display: flex;
  gap: 50px;
  width: 100%;
  border-bottom: 2px solid var(--border-color);
  padding-bottom: 15px;

  position: relative;
}

.tabs .tab_name p {
  margin-left: 12px;
  transition: 0.4s ease-in-out;
  cursor: pointer;
}
.tabs .tab_name p:hover,
.tabs .tab_name p:active {
  color: #daa520;
  font-size: 18px;
  font-weight: 600;
}

.tabs .tab_name::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 12%;
  height: 2px;
  /* background-color: #808080; */
}

.main_content .table {
  overflow-x: auto;
}

.main_content table {
  border-collapse: collapse;
  width: 100%;
}

.table td,
.table th {
  padding: 25px 20px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
}
/* Apply background and text color to selected table row */
.selected-row {
  background: #5bb9c0;
  color: #fff;
}
.table tr {
  transition: background-color 0.3s, color 0.3s, border-radius 0.3s,
    transform 0.3s;
}
.table tr:hover,
.table tr:nth-child(even):hover {
  background-color: #5bb9c0;
  color: #fff;
  border-radius: 10px;
  transform: scale(1.02); /* Enlarge the row on hover */
}

.table tr:nth-child(even).selected-row {
  background: #5bb9c0;
  color: #fff;
}

.table tr:nth-child(even) {
  background: #d4d7d77c;
}

.table img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.table .profile_name {
  display: flex;
  align-items: center;
  gap: 7px;
}

/*----- Right SideBar -----*/

.right_sidebar {
  flex-basis: 20%;
  position: sticky;
  top: 0px;
  align-self: flex-start;
  transition: all 0.3s ease-in-out;
}

.notification_and_name {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 20px;
  padding-bottom: 35px;
  border-bottom: 2px solid var(--border-color);
  cursor: pointer;
  position: relative;
}

.notification_and_name img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-position: center;
  object-fit: cover;
  margin-right: -20px;
}

.notification_and_name .bell {
  position: relative;
  font-size: 20px;
}

.notification_and_name span {
  position: absolute;
  background: #ff0000;
  height: 5px;
  width: 5px;
  right: 0px;
  top: 2px;
  border-radius: 50%;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 15px;
}

.profile img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  background-position: center;
}

.profile .name_and_class {
  text-align: center;
}

.profile .name_and_class P {
  font-weight: 600;
}

.profile .name_and_class span {
  font-size: 14px;
  color: var(--grey-color);
}

.profile .contact_info {
  display: flex;
  gap: 30px;
  font-size: 22px;
  cursor: pointer;
}

.profile .about {
  margin: 15px 0;
}

.profile .about h4,
.profile .other_info h4 {
  font-weight: 600;
  font-size: 14px;
}

.profile .about p,
.profile .other_info p {
  font-size: 12px;
  margin-top: 10px;
  color: var(--grey-color);
}

.profile .other_info {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.profile .other_info div {
  width: 120px;
}

.profile .student_from_same_class {
  width: 300px;
}

.profile .student_from_same_class img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  background-position: center;
  margin-left: -8px;
}

.profile .student_from_same_class img:nth-child(1) {
  margin-left: 0 !important;
}

.profile .student_from_same_class h4 {
  font-weight: 600;
  font-size: 14px;
  margin: 10px 0;
}

.profile .student_same_class_img {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile .student_same_class_img span {
  color: #5bb9c0;
  font-size: 14px;
  margin-left: 12px;
}

.left_sidebar.open {
  transform: translate(0);
}

/*----- Media Query -----*/

@media (max-width: 500px) {
  /*----- left_sidebar -----*/
  .left_sidebar {
    transform: translateX(-150%);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 330px;
    height: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .close_btn {
    position: absolute;
    top: 5%;
    left: 10%;
    z-index: 120;
    color: #000;
    border: none;
    font-size: 30px;
    background-color: transparent;
    display: block;
  }
  .left_sidebar .close_hamburger_btn {
    display: block;
  }

  .left_sidebar .logo h2 {
    margin-top: 100px;
    margin-bottom: 20px;
    color: #000;
  }

  .left_sidebar .menu_items .menu_item {
    font-size: 16px;
  }

  /*----- right_sidebar -----*/
  .right_sidebar {
    transform: translateX(150%);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .right_sidebar .profile {
    gap: 25px;
    padding: 25px;
    text-align: center;
  }

  .profile .other_info {
    justify-content: center;
  }

  .profile .name_and_class P,
  .profile .about h4,
  .profile .other_info h4 {
    font-size: 15px;
    color: #000;
  }

  .profile .name_and_class span {
    font-size: 15px;
  }

  .notification_and_name {
    color: #000;
  }

  .notification_and_name .bx-chevron-down {
    display: none;
  }

  .notification_and_name .close_btn,
  .notification_and_name .bell {
    font-size: 28px;
  }

  .profile .contact_info {
    font-size: 24px;
    gap: 20px;
    color: #000;
  }

  .profile .about p,
  .profile .other_info p,
  .profile .student_from_same_class h4 {
    font-size: 14px;
  }

  .notification_and_name img {
    height: 50px;
    width: 50px;
  }

  .notification_and_name {
    justify-content: center;
    gap: 35px;
  }

.search_box {
    background: var(--border-color);
    display: flex;
    gap: 15px;
    padding: 12px;
    margin-top: 20px;
    align-items: center;
    color: #000;
    width: 100%;
    border-radius: 5px;
    font-size: 20px;
    width: 90%;
}

.search_box input {
    width: 100%;
    border: none;
    background: #e9eaec;
    outline: none;
}

.menu_item_name_and_filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;
    width: 50%;
}

.menu_item_name_and_filter .main_navbar {
    width: 100%;
}

.menu_item_name_and_filter .menu_item_name h2 {
    font-weight: 600;
    font-size: 24px;
}

.menu_item_name_and_filter .filter_and_sort {
    display: flex;
    gap: 20px;
    align-items: center;
}

.menu_item_name_and_filter .filter_and_sort .sort_and_filter {
    display: flex;
    align-items: center;
    gap: 10px;
    background: var(--border-color);
    color: #000;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.tabs {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    margin-left: 15px;
}

.tabs .tab_name {
    display: flex;
    gap: 50px;
    width: 100%;
    border-bottom: 2px solid var(--border-color);
    padding-bottom: 15px;
    position: relative;
}

.tabs .tab_name p {
    margin-left: 12px;
    transition: 0.4s ease-in-out;
    cursor: pointer;
}
.tabs .tab_name p:hover{
    color: #daa520;
    font-size: 18px;
    font-weight: 600;
}

.tabs .tab_name::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 12%;
    height: 2px;
    /* background-color: #808080; */
}

.main_content .table {
    overflow-x: auto;
}

.main_content table {
    border-collapse: collapse;
    width: 100%;
}

.table td,
.table th {
    padding: 25px 20px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
}
/* Apply background and text color to selected table row */
.selected-row {
    background: #5bb9c0;
    color: #fff;
}
.table tr {
    transition: background-color 0.3s, color 0.3s, border-radius 0.3s, transform 0.3s;
}
.table tr:hover, .table tr:nth-child(even):hover{
    background-color: #5bb9c0;
    color: #fff;
    border-radius: 10px;
    transform: scale(1.02); /* Enlarge the row on hover */
}

/* .selected-row{
    background: #5bb9c0;
    color: #fff;
} */
.table tr:hover, .table tr:nth-child(even):hover{
    background-color: #5bb9c0;
    color: #fff;
    border-radius: 10px;
    transform: scale(1.02); /* Enlarge the row on hover */
}
.table .selected-row , .table .selected-row:nth-child(even){
    background-color: #5bb9c0;
    color: #fff;
    border-radius: 10px;
    transform: scale(1.02); /* Enlarge the row on hover */
}
/* .table .selected-row:nth-child(even){
    background: #d4d7d77c;
    color: #000;
    border-radius: 10px;
    transform: scale(1.02); 
} */


.table tr:nth-child(even) {
    background: #d4d7d77c;
}


.table img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.table .profile_name {
    display: flex;
    align-items: center;
    gap: 7px;
}


/*----- Right SideBar -----*/

.right_sidebar {
    flex-basis: 20%;
    position: sticky;
    top: 0px;
    align-self: flex-start;
    transition: all 0.3s ease-in-out;
}

.notification_and_name {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    margin-top: 20px;
    padding-bottom: 35px;
    border-bottom: 2px solid var(--border-color);
    cursor: pointer;
    position: relative;
}



.notification_and_name img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: center;
    object-fit: cover;
    margin-right: -20px;
}

.notification_and_name .bell {
    position: relative;
    font-size: 20px;
}

.notification_and_name span {
    position: absolute;
    background: #ff0000;
    height: 5px;
    width: 5px;
    right: 0px;
    top: 2px;
    border-radius: 50%;
}

.profile {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .notification_and_name .close_btn {
    display: block;
  }

  /*----- Main Content -----*/
  .main_content {
    flex-basis: 100%;
    width: 100%;
  }

  .main_content .left_right_sidebar_opener {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main_content .left_right_sidebar_opener .hamburger {
    text-align: left;
    display: inline;
  }

  .main_content .left_right_sidebar_opener .user {
    text-align: right;
    display: inline;
  }

  .table td,
  .table th {
    font-size: 15px;
  }

  input::placeholder {
    font-size: 14px;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  /*----- left_sidebar -----*/
  .left_sidebar {
    transform: translateX(-150%);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 330px;
    height: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .close_btn {
    position: absolute;
    top: 5%;
    left: 10%;
    z-index: 120;
    color: #000;
    border: none;
    font-size: 30px;
    background-color: transparent;
    display: block;
  }

  .left_sidebar .close_hamburger_btn {
    display: block;
  }

  .left_sidebar .logo h2 {
    margin-top: 100px;
    margin-bottom: 20px;
    color: #000;
  }

  .left_sidebar .menu_items .menu_item {
    font-size: 16px;
  }

  /*----- right_sidebar -----*/
  .right_sidebar {
    transform: translateX(150%);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .right_sidebar .profile {
    gap: 25px;
    padding: 25px;
    text-align: center;
  }

  .profile .other_info {
    justify-content: center;
  }

  .profile .name_and_class P,
  .profile .about h4,
  .profile .other_info h4 {
    font-size: 15px;
    color: #000;
  }

  .profile .name_and_class span {
    font-size: 15px;
  }

  .notification_and_name {
    color: #000;
  }

  .notification_and_name .bx-chevron-down {
    display: none;
  }

  .notification_and_name .close_btn,
  .notification_and_name .bell {
    font-size: 28px;
  }

  .profile .contact_info {
    font-size: 24px;
    gap: 20px;
    color: #000;
  }

  .profile .about p,
  .profile .other_info p,
  .profile .student_from_same_class h4 {
    font-size: 14px;
  }

  .notification_and_name img {
    height: 50px;
    width: 50px;
  }

  .notification_and_name {
    justify-content: center;
    gap: 35px;
  }

  .student_from_same_class {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .notification_and_name .close_btn {
    display: block;
  }

  /*----- Main Content -----*/
  .main_content {
    flex-basis: 100%;
    width: 100%;
  }

  .main_content .left_right_sidebar_opener {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main_content .left_right_sidebar_opener .hamburger {
    text-align: left;
    display: inline;
  }

  .main_content .left_right_sidebar_opener .user {
    text-align: right;
    display: inline;
  }

  .table td,
  .table th {
    font-size: 15px;
  }

  input::placeholder {
    font-size: 14px;
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  /*----- left_sidebar -----*/
  .left_sidebar {
    transform: translateX(-150%);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 330px;
    height: 100%;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  .close_btn{
    display: block;
  }

  .left_sidebar .close_hamburger_btn {
    display: block;
  }

  .left_sidebar .logo h2 {
    margin-top: 100px;
    margin-bottom: 20px;
    color: #000;
  }

  .left_sidebar .menu_items .menu_item {
    font-size: 16px;
  }

  /*----- right_sidebar -----*/
  .right_sidebar {
    transform: translateX(150%);
    overflow: hidden;
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #fff;
    width: 100%;
    height: 100%;
    overflow: scroll;
  }

  .right_sidebar .profile {
    gap: 25px;
    padding: 25px;
    text-align: center;
  }

  .profile .other_info {
    justify-content: center;
  }

  .profile .name_and_class P,
  .profile .about h4,
  .profile .other_info h4 {
    font-size: 15px;
    color: #000;
  }

  .profile .name_and_class span {
    font-size: 15px;
  }

  .notification_and_name {
    color: #000;
  }

  .notification_and_name .bx-chevron-down {
    display: none;
  }

  .notification_and_name .close_btn,
  .notification_and_name .bell {
    font-size: 28px;
  }

  .profile .contact_info {
    font-size: 24px;
    gap: 20px;
    color: #000;
  }

  .profile .about p,
  .profile .other_info p,
  .profile .student_from_same_class h4 {
    font-size: 14px;
  }

  .notification_and_name img {
    height: 50px;
    width: 50px;
  }

  .notification_and_name {
    justify-content: center;
    gap: 35px;
  }

  .student_from_same_class {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }

  .notification_and_name .close_btn {
    display: block;
  }

  /*----- Main Content -----*/
  .main_content {
    flex-basis: 100%;
    width: 100%;
  }

  .main_content .left_right_sidebar_opener {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .main_content .left_right_sidebar_opener .hamburger {
    text-align: left;
    display: inline;
  }

  .main_content .left_right_sidebar_opener .user {
    text-align: right;
    display: inline;
  }

  .table td,
  .table th {
    font-size: 15px;
  }

  input::placeholder {
    font-size: 14px;
  }
}



/* Date Time Picker */

/* RESET FORM ELEMENTS */
.input-container input[type="date"],
.input-container input[type="text"] {
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
}

/* INPUT CONTAINER  */
.input-container {
  display: flex;
  flex-direction: column;
  width: auto;
  height: auto;
  min-width: 217px;
  height: 60px;
  padding: 5px 10px 10px 10px;
  background: #fff;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 3px #000);
  -webkit-filter: drop-shadow(0px 0px 3px #000);
  overflow: hidden;
  cursor: pointer;
}

.input-container p{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 90%;
  padding: 10px;
  cursor: pointer;
}

.input-container:hover,
.input-container:focus,
.input-container:active {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.input-container label {
  position: absolute;
  width: 100%;
  font-family: "Segoe UI";
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0.1em;
  line-height: 20px;
  color: #09f;
  margin-left: 2px;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 100;
}

.input-container input {
  position: relative;
  align-items: center;
  width: auto;
  height: auto;
  font-family: "Lekton", Arial, sans-serif;
  font-weight: 600;
  font-size: 22px;
  letter-spacing: 0.05em;
  line-height: 21px;
  text-transform: uppercase;
  margin-left: 0px;
}

/* BUTTON CONTAINER */
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 197px;
  height: 65px;
  padding: 1px;
  padding-bottom: 2px;
  background: #09f;
  border-radius: 8px;
  filter: drop-shadow(0px 0px 3px #000);
  -webkit-filter: drop-shadow(0px 0px 3px #000);
  overflow: hidden;
  cursor: pointer;
}

.button-container:hover,
.button-container:focus {
  background: #09f;
  filter: drop-shadow(0px 0px 3px #09f);
  -webkit-filter: drop-shadow(0px 0px 3px #09f);
}

/* -- button -- */
.button-container .button {
  position: relative;
  width: 100%;
  min-height: 63px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}

.button-container:hover .button:hover {
  background: #000;
  color: #fff;
}

.button-container:hover .button:active {
  background: #000;
  color: #fff;
  background: #09f;
  filter: drop-shadow(0px 0px 3px #09f);
  -webkit-filter: drop-shadow(0px 0px 3px #09f);
}

/* -- button ok -- */
.button-container .button.ok {
  background: #131313;
  color: #fff;
}

.button-container:hover .button.ok:hover {
  background: #131313;
  color: #09f;
  text-shadow: 0px 0px 10px #09f;
  -webkit-text-shadow: 0px 0px 10px #09f;
}

.button-container:hover .button.ok:active {
  color: #fff;
  background: #09f;
  filter: drop-shadow(0px 0px 3px #000);
  -webkit-filter: drop-shadow(0px 0px 3px #000);
}

/* RESERVATION BOX */
.reservation-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: auto;
  padding: 20px;
  border-radius: 7px;
  background-color: rgba(255, 255, 255, 1);
}

.reservation-box .static {
  position: relative;
  display: flex;
  align-items: center;
  float: left;
  width: auto;
  margin-right: 20px;
}

.reservation-box .flex {
  position: relative;
  display: flex;
  float: left;
  flex-direction: table;
  align-items: center;
  gap: 20px;
  width: auto;
}

.reservation-box .top {
  display: flex;
  flex-direction: table;
}

.reservation-box .bottom {
  position: relative;
  width: 100%;
  text-align: right;
}

.reservation-box .info {
  width: auto;
  color: #000;
  font-weight: 500;
  text-decoration: none;
  text-align: right;
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.reservation-box .info:hover,
.reservation-box .info:focus,
.reservation-box .info:active {
  color: #000;
  border-bottom: 2px solid #09f;
}

/* RESERVATION BOX SMALL */
.reservation-box.small,
.reservation-box.small .flex {
  flex-direction: column;
}

.reservation-box.small .top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.reservation-box.small .static {
  margin-right: 0px;
}

.reservation-box.small .bottom {
  text-align: center;
}

.reservation-box.small .button-container {
  min-width: 217px;
}

/* data Pop up */

/* Popup container */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure popup is on top of other content */
}

/* Popup content */
.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* Soft shadow effect */
  max-width: 400px;
  width: 90%;
  text-align: center;
  overflow-y: scroll;
  height: 80%;
}

/* Title */
.popup-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

/* Close button */
.popup-content button {
  background-color: #ff5252;
  /* Red */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-content button:hover {
  background-color: #ff1744;
  /* Darker red on hover */
}

/* Additional styling for paragraph */
.popup-content p {
  margin-bottom: 8px;
  font-size: 18px;
}

/* Side bar content */

/* .sidebar {
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    left: -250px;
    background-color: #f4f4f4;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease;
  }
  
  .sidebar.open {
    left: 0;
    z-index: 999;
  }
  .sidebar .menu_items{
    margin-top: -30px;
  }
  
  .sidebar .logo {
    padding: 20px;
  }
  
  .sidebar .logo img {
    width: 100%;
    max-width: 150px;
  }
  
  .menu_items {
    margin-top: 20px;
  }
  
  .menu_item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .menu_item:hover {
    background-color: #e0e0e0;
  }
  
  .menu_item p {
    margin-left: 10px;
  }
  
  .menu_item.active {
    background-color: #5bb9c0;
    color: #fff;
  }
  
  .hamburger {
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
    z-index: 1000;
  }
  
  .hamburger svg {
    font-size: 24px;
  }
  
  @media (max-width: 908px) {
    .sidebar {
      width: 200px;
    }
  
    .hamburger {
      display: block;
    }
  
    .menu_items {
      display: none;
    }
  
    .sidebar.open .menu_items {
      display: block;
      margin-top: -30px;
    }
    .sidebar.open .menu_item{
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sidebar.open .menu_item p{
        font-size: 15px;
    }
    .sidebar.open .icon{
        font-size: 15px;
    }
  } */
