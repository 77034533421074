*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.app-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    text-align: center;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
 
  
  
  
  .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; 
    gap: 20px;
    margin-top: 20px;
    padding: 20px;
    margin-top: 7rem;
  }
  
  .profile {
    text-align: center;
    width: 30%;
  }
  
  .profile-image {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
  }
  
  .profile h3 {
    margin: 10px 0;
    font-size: 18px;
    font-weight: bold;
    color: #333;
  }
  
  .profile p {
    font-size: 14px;
    color: #777;
  }
  
  .form-container {
    flex: 1;
    max-width: 500px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    margin: auto;
  }
  
  h2 {
    color: #ff9800;
    text-align: center;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  form input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: border-color 0.3s ease;
  }
  
  form input:focus {
    border-color: #4caf50;
    outline: none;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 12px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .error-text {
    color: #e74c3c;
    font-size: 12px;
  }
  
  .success-message,
  .error-message {
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .success-message {
    color: #2ecc71;
  }
  
  .error-message {
    color: #e74c3c;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .content {
      flex-direction: column;
      align-items: center;
    }
  
    .profile {
      width: 80%;
    }
  
    .form-container {
      width: 90%;
    }
  
    .profile-image {
      width: 120px;
      height: 120px;
    }
  }
  
  @media (max-width: 480px) {
    form input {
      font-size: 14px;
      padding: 10px;
    }
  
    .submit-button {
      font-size: 14px;
      padding: 10px;
    }
  
    h2 {
      font-size: 18px;
    }
  
    .profile h3 {
      font-size: 16px;
    }
  
    .profile p {
      font-size: 12px;
    }
  }
  