.auth-form-container {
  font-family: "Open Sans", sans-serif;
  background: #f9faff;
  color: #3a3c47;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
}
.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.auth-form {
  background: #fff;
  max-width: 360px;
  width: 100%;
  padding: 58px 44px;
  border: 1px solid #e1e2f0;
  border-radius: 4px;
  box-shadow: 0 0 5px 0 rgba(42, 45, 48, 0.12);
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-container {
  width: 200px;
  height: 50px;
  margin: 0 auto;
}
.row {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  color: #333;
}

.row label {
  font-size: 13px;
  color: #8086a9;
}

.row input {
  flex: 1;
  padding: 13px;
  border: 1px solid #d6d8e6;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.2s ease-out;
  margin-bottom: 15px;
}

label {
  position: relative;
}

label input {
  font-family: "Open Sans", sans-serif;
  font-size: 1rem;
  color: #f9f9f9;
  background: transparent;
  padding: 1rem 3.2rem 1rem 1.2rem;
  min-width: 24rem;
  border-radius: 0.2rem;
  border: 2px solid #7a7a7a56;
  transition: border-color 0.2s;
}

.password-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 62%;
  right: 3rem;
  transform: translateY(-50%);
  width: 1.2rem;
  color: #010101;
  transition: color 0.2s;
}

.password-icon .feather-eye-off {
  display: none;
}

.password-icon:hover {
  cursor: pointer;
  color: #ff4754;
}

.auth-button {
  width: 100%;
  padding: 12px;
  font-size: 18px;
  background: #15c39a;
  color: #fff;
  border: none;
  border-radius: 100px;
  cursor: pointer;
  font-family: "Open Sans", sans-serif;
  margin-top: 15px;
  transition: background 0.2s ease-out;
}

.auth-button:hover {
  background-color: #2980b9;
}

p {
  margin-top: 10px;
  text-align: center;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

@media screen and (max-width: 767px) {
  .auth-form {
    width: 90%;
  }
}

@media (max-width: 458px) {
  body {
    margin: 0 18px;
  }
  .auth-form-container {
    width: 70%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  form {
    background: #f9faff;
    border: none;
    box-shadow: none;
    padding: 20px 0;
  }
}
.container {
  width: 69%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card {
  width: 50%;
  background: #ffffff;
  border-radius: 5px;
  padding: 60px 0 40px 0;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.password-icon {
  display: flex;
  align-items: center;
  position: absolute;
  top: 62%;
  right: 0.6rem;
  transform: translateY(-50%);
  width: 1.2rem;
  transition: color 0.2s;
  font-size: 25px;
  z-index: 9;
}

.password-icon .feather-eye-off {
  display: none;
}

.password-icon:hover {
  cursor: pointer;
  color: #ff4754;
}

.card .title {
  position: relative;
  z-index: 1;
  border-left: 5px solid #e65100;
  margin: 0 0 35px;
  padding: 10px 0 10px 50px;
  color: #e65100;
  font-size: 220%;
  font-weight: 600;
  text-transform: uppercase;
}

.card .auth-input-container {
  position: relative;
  margin: 0 40px 25px;
}

.card .auth-input-container input {
  outline: none;
  z-index: 1;
  position: relative;
  background: none;
  width: 100%;
  height: 40px;
  border: 0;
  color: #212121;
  font-size: 24px;
  font-weight: 400;
}

.card .auth-input-container .bar {
  position: absolute;
  left: 0;
  bottom: 0;
  background: #757575;
  width: 100%;
  height: 1px;
}

.card .button-container1 {
  margin: 0 30px;
  margin-bottom: 20px;
  text-align: center;
}

.card .button-container1 button {
  outline: 0;
  cursor: pointer;
  position: relative;
  background: 0;
  width: 70%;
  border: 2px solid #e3e3e3;
  padding: 12px 0;
  font-size: 90%;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  border-radius: 10px;
  background-color: #15c39a;
}

.card .button-container1 button:hover {
  background-color: #2980b9;
}

.card .button-container1 button:hover,
.card .button-container1 button:active,
.card .button-container1 button:focus {
  border-color: #ffffff;
}

.card .forgot {
  color: #d3d3d3;
  font-size: 180%;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
}

.card .forgot:hover {
  color: #bababa;
}

.info {
  color: white;
  background-color: #000000ba;
  background-image: url(https://images.pexels.com/photos/302769/pexels-photo-302769.jpeg?cs=srgb&dl=pexels-pixabay-302769.jpg&fm=jpg);
  background-size: cover;
}

.info img {
  display: block;
  margin: 0 auto;
}

.info h1 {
  text-align: center;
  font-size: 200%;
  font-weight: 200;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);
  z-index: 3;
  color: #000;
  background-color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    inset 0 4px 8px rgba(0, 0, 0, 0.15), inset 0 8px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  font-weight: 500;
}

.info .para {
  position: relative;
  text-align: center;
  font-size: 135%;
  padding: 4%;
  font-style: italic;
  line-height: 145%;
}

.info .copy {
  text-align: center;
  margin-bottom: 6px;
}

@media only screen and (max-width: 1094px) {
  .container {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .eq {
    display: table;
    width: 90%;
  }

  .info h1 {
    font-size: 18px;
  }

  .col {
    float: none;
    display: table-cell;
    vertical-align: top;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
  }

  .card {
    width: 100%;
  }

  .progress .bar {
    width: 1%;
  }

  .progress #right {
    background-color: #fff;
  }

  .info h1 {
    display: none;
  }
  .logo img {
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
      inset 0 4px 8px rgba(0, 0, 0, 0.15), inset 0 8px 16px rgba(0, 0, 0, 0.15);
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes slide-up {
  from {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slide-up {
  from {
    -webkit-transform: translateY(12px);
    transform: translateY(12px);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.animated {
  -webkit-animation: slide-up 0.7s ease-in, fade-in 0.7s ease-in;
  animation: slide-up 0.7s ease-in, fade-in 0.7s ease-in;
  -webkit-animation-play-state: running;
  animation-play-state: running;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}