.con {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .date-input-wrapper {
    margin-bottom: 20px;
  }
  
  .static {
    margin-bottom: 10px;
  }
  
  .flex {
    display: flex;
    align-items: center;
  }
  
  .input-container {
    margin-right: 10px;
  }
  
  .date-field {
    width: 150px;
    padding: 5px;
  }
  
  .button-container {
    margin-left: 10px;
  }
  .table-wrapper-container{
    display: flex;
  }
  .button.ok {
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
  }
  
  .button.ok {
    background-color: #28a745;
  }
  
  .button.ok:hover {
    background-color: #218838;
  }
  .table-wrapper h2{
    font-size: 20px;
  }
  .tables-wrapper {
    display: flex;
  }
  
  .table-wrapper {
    flex: 1;
    margin-right: 20px;
  }
  .table-wrapper table{
    border-spacing: 0;
    overflow: hidden;
    inline-size: 100%;
    text-align: left;
    background-color: inherit;
    border: 1px solid lightgray;
    border-radius: 0.5rem;
    box-shadow: 0px 4px 6px -2px rgba(14, 30, 37, 0.12);
  }
  .channel-partner {
    background-color: #007BFF;
  }
  
  .customer {
    background-color: #FFC046;
  }

  .data-table td,
.data-table th {
    border-right: 1px solid #E8E8E8;
    empty-cells: show;
    padding: 10px;
    border-top: 1px solid #E8E8E8;
    border-bottom: 1px solid #E8E8E8;
    font-size: 15px;
}
.data-table tr {
    transition: background-color 0.3s, color 0.3s, border-radius 0.3s,
      transform 0.3s;
  }
  .data-table tr:hover,
.data-table tr:nth-child(even):hover {
  background-color: #5bb9c0;
  color: #fff;
  border-radius: 10px;
  transform: scale(1.02); /* Enlarge the row on hover */
}
.data-table tr:nth-child(even).selected-row {
    background: #5bb9c0;
    color: #fff;
  }
  
  .data-table tr:nth-child(even) {
    background: #d4d7d77c;
  }
  @media (max-width: 768px) {
    .flex {
      flex-direction: column;
    }
  
    .input-container {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .button-container {
      margin-left: 0;
    }
  
    .button {
      width: 100%;
      text-align: center;
    }
  
    .tables-wrapper {
      flex-direction: column;
    }
  
    .table-wrapper {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  