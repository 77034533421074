.box {
  padding: 20px;
  border: 2px solid #aba9a9;
  border-radius: 5px;
  margin-bottom: 20px;
  cursor: pointer;
  height: 150px;
  display: grid;
  align-items: center;
  justify-items: center;
  background-size: cover;
}
.box img {
  height: 100px;
  width: 100px;
}

.box h2 {
  margin: 0;
  font-size: 18px;
  color: #333;
  position: relative;
  bottom: -10px;
  color: #007bff;
  display: contents;
}

.box a {
  display: block;
  margin-top: 10px;
  text-decoration: none;
  color: #007bff;
}

.box a:hover {
  text-decoration: underline;
}
