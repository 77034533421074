* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.customerFormWrapper {

  width: 80%;
  height: auto;
  padding: 30px;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  row-gap: 15px;
  margin: 0 auto;
  margin-top: 50px;
}

.customerFormWrapper .section-head {
  width: 100%;
  text-align: center;
  position: relative;
  background-color: #6592c4;
  border-radius: 8px 8px 0 0;
  padding: 20px;
  color: #fff;
}
.section-head >h2{
  color: white;
}

.customerFormWrapper .section-head h2 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}

.customerFormWrapper .section-head::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 60px;
  height: 4px;
  bottom: -8px;
  border-radius: 10px;
}

.customerFormWrapper .form-container {
  width: 100%;
  display: grid;
  gap: 20px;
}

.customerFormWrapper form {
  display: grid;
  gap: 20px;
}

.customerFormWrapper .form-container > form div {
  display: flex;
  flex-direction: column;
}

.customerFormWrapper label {
  font-weight: 600;
  margin-bottom: 8px;
}

.customerFormWrapper input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="date"],
textarea,
select {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-top: 5px;
  transition: border 0.3s ease;
}

.customerFormWrapper input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="date"] {
  width: 100% !important
 
}

.customerFormWrapper input[type="text"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
textarea:focus,
select:focus {
  border-color: #007bff;
  outline: none;
}

.customerFormWrapper textarea {
  resize: vertical;
  min-height: 100px;
  max-width: 1013px;
}

.customerFormWrapper button[type="submit"] {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.customerFormWrapper button[type="submit"]:hover {
  background-color: #0056b3;
}

.customerFormWrapper .error {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.customerFormRow {
  display: flex;
  gap: 20px;
}

.customerFormRow label {
  flex-basis: 48%;
}

@media screen and (max-width: 767px) {
  .customerFormWrapper {
    width: 95%;
    padding: 20px;
    margin-top: 40px;
  }
  .customerFormWrapper input[type="text"],
  textarea,
input[type="email"],
input[type="tel"],
input[type="number"],
input[type="date"] {
  width: 100% !important
 
}

  .customerFormWrapper .section-head {
    padding: 15px;
  }

  .customerFormWrapper label {
    text-align: left;
  }

  .customerFormWrapper .form-container > form div {
    display: block;
  }

  .customerFormWrapper .form-container input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    width: 100%;
  }

  .customerFormWrapper button[type="submit"] {
    width: 100%;
  }
}
