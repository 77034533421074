.img-container {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img-container img {
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .img-container {
    top: 5%;
    height: 30px;
  }
}

.spinner {
  position: fixed;
  top: 0;
  left: 0;
  /* transform: translate(-50%, -50%); */
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.165);
  background-size: 600%;
  background-position: 0 100%;
  z-index: 9;
  display: none;
}

.spinner.show {
  display: block;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.triple-spinner {
  display: block;
  position: relative;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid #ff5722;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  z-index: 99;
}

.triple-spinner::before,
.triple-spinner::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}

.triple-spinner::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: #ff9800;
  -webkit-animation: spin 3s linear infinite;
  animation: spin 3.5s linear infinite;
}

.triple-spinner::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: #ffc107;
  -webkit-animation: spin 1.5s linear infinite;
  animation: spin 1.75s linear infinite;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.logout-super{
  width: 90%;
  margin: 0 auto;
}
#logout-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 20px;
  position: fixed;
  top: 20px;
  right: 5px;
}

#logout-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-justify: justify;
  border-radius: 10px;
  width: 150px;
}

#logout-button:hover {
  background-color: #3e8e41;
}

#logout-button:active {
  background-color: #2e6e31;
}

#logout-menu {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 10;
  list-style: none;
  padding: 0;
  margin: 0;
}

#logout-menu.hidden {
  display: none;
}

#logout-menu li {
  color: black;
  padding: 10px 20px;
  cursor: pointer;
}

#logout-menu li:hover {
  background-color: #f1f1f1;
}

@media (max-width: 768px) {
  #logout-container {
    position: absolute;
    top: 14%;
    left: 45%;
    transform: translate(-50%, -50%);
    z-index: 9;
  }
  #logout-button{
    padding: 7px;
    width: 135px
  }
}


.logout-container-dash {
  position: fixed;
  top: 20px; /* Adjust as needed */
  right: 20px; /* Adjust as needed */
  z-index: 999; /* Ensure it's above other content */
}

#logout-container {
  /* Add any additional styling for the container */
}

#logout-button-dash {
  /* Add any additional styling for the button */
  background-color: #007bff; /* Example background color */
  color: #ffffff; /* Example text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

#logout-button-dash:hover {
  background-color: #0056b3; /* Example hover background color */
}
