.table table h2 {
  columns: red;
}
.action-icons {
  display: flex;
  justify-content: space-between;
}
.add-sales {
  width: 150px;
  height: 30px;
  border-radius: 10px;
  background-color: #3EB943;
  padding: 10px;
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center; /* Vertically center the content */
  cursor: pointer;
}
.add-sales p {
  color: #fff;
  margin: 0; /* Remove default margin */
}
.add-sales .icon {
  color: #fff;
  margin-right: 5px; /* Adjust spacing between the icon and text */
}
.action-icons div {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.action-icons div:hover {
  background-color: #f0f0f0;
}

.approve-icon {
  color: green;
}

.disapprove-icon {
  color: red;
}
.approved {
  color: green;
}

.disapproved {
  color: red;
}

.pending {
  color: orange;
}

.edit-icon {
  color: blue;
}
.delete-icon {
  color: red;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
  width: 350px;
  justify-content: center;
}

.modal.active {
  display: block;
}

.modal h2 {
  margin-bottom: 20px;
}

.modal input {
  width: 90%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-group {
  display: flex;
  justify-content: space-evenly;
}

.save-btn,
.close-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.save-btn {
  background-color: #4caf50;
  color: white;
}

.close-btn {
  background-color: #f44336;
  color: white;
}

.save-btn svg,
.close-btn svg {
  margin-right: 5px;
}

/* Styles for delete confirmation modal */
.delete-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}

.delete-modal h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.delete-modal p {
  font-size: 1rem;
  margin-bottom: 20px;
}

.delete-modal .button-group {
  display: flex;
  justify-content: center;
}

.delete-modal button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-modal .delete-btn {
  background-color: #e74c3c;
  color: #fff;
}

.delete-modal .close-btn {
  background-color: #3498db;
  color: #fff;
}

.delete-modal button:hover {
  opacity: 0.8;
}
