.customerPhoneNumberPagecontainer {
  width: 90%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  margin-top: 30px;
  position: relative;
  top: 70px;
}

.customerPhoneNumberPagecontainer h2 {
  text-align: center;
}
.phone-number-container .name-container{
  display: grid;
}
.name-container input{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;

}

.phone-number-container,
.otp-container {
  text-align: center;
  margin-bottom: 20px;
}

.phone-number-input,
.otp-input {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.phone-number-input input,
.otp-input input {
  width: 35px;
  height: 35px;
  margin: 0 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
} 

.customerPhoneNumberPagecontainer button {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
}

.customerPhoneNumberPagecontainer button:hover {
  background-color: #0056b3;
}


@media screen and (max-width: 767px) {
   .customerPhoneNumberPagecontainer{
       width: 80%;
       position: absolute;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
   }
   .phone-number-input{
      flex-wrap: wrap;
   }
   .phone-number-input input{
      height: 10px;
      width: 10px;
      margin: 1.5px;
   }
}