.customer-revisit-form {
    display: flex;
    flex-direction: column; 
    gap: 1rem;  
    width: 40%; 
    border: 1px solid #ddd;
    border-radius: 4px; 
    padding: 1rem; 
    background-color: #f5f5f5; 
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .customer-revisit-checker {
    display: flex;
    justify-content: center;
    align-items: center; 
  }
  .customer-revisit-update{
    display: flex;
  }
  
  .customer-input-check {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    align-items: center;
  }
  
  .customer-input-check label{
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
  }
  .customer-input-check input {
    width: 60%; 
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px; 
    font-size: 1rem; 
  }
  .customer-input-update{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    align-items: center;
  }
  .customer-input-update input{
    width: 60%; 
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem; 
    margin-bottom: 20px;
  }
  
  .customer-revisit-update button {
    margin-left: 1rem; 
    padding: 0.5rem 1rem;
    border: none; 
    border-radius: 4px;
    background-color: #3498db; 
    color: #fff; 
    cursor: pointer; 
    font-size: 1rem; 
    width: 50%;
    align-items: center;
    justify-content: center;
  }
  
  .customer-revisit-update button:hover {
    background-color: #2980b9; 
  }  
  .selection-check{
    background: #fff;
    width: 60%;
    display: grid;
    position: absolute;
    top: 33%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    max-height: 200px; 
    overflow-y: auto; 
  }
  .selection-check ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .selection-check li {
    padding: 8px;
    border-bottom: 1px solid #ccc;
    width: 200px;
    cursor: pointer;
  }
  
  .selection-check li:last-child {
    border-bottom: none;
  }
  
  .error-message {
    color: red;
    margin-top: 5px;
  }