.formSelectionPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.formSelectionBoxWrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 90px;
  margin-top: 4rem;

}

.error-message {
  color: red;
  font-size: 0.9em;
  margin-top: 0.25em;
}



@media screen and (max-width: 767px) {
  .formSelectionPageWrapper{
    width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-top: 30px;
  }
  .formSelectionBoxWrapper{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
}